import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();

  const handleDisconnectAndRedirect = () => {
    room!.disconnect();
    setTimeout(() => {
      // close tab if it's opened by window.open()
      window.top?.close();
      window.close();
      
      // otherwise location href to finish call page
      const saas_website_address = process.env.REACT_APP_TVMD_SAAS_WEBSITE+'/finishConsultCall.php';
      window.location.href=saas_website_address;
    }, 1000);
  }
  return (
    <Button onClick={handleDisconnectAndRedirect} className={clsx(classes.button, props.className)} data-cy-disconnect>
      Disconnect
    </Button>
  );
}
